import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import { useRecoilState } from 'recoil';
import { openUpdateInactiveModalState } from '../../../../../atoms';
import Notify from '../../../../../components/Wrapper/Notify';
import { Color } from '../../../../../constants';

const UpdateInactiveUserModal = () => {
  const [isOpen, setOpen] = useRecoilState(openUpdateInactiveModalState);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  useClickAway(containerRef, handleClose);

  return (
    <Notify open={isOpen}>
      <Container ref={containerRef}>
        <Article>
          <span className="title">휴면 상태가 해제되었습니다!</span>
          <span className="subTitle">
            이제 스몹의 모든 서비스를 <br />
            정상적으로 이용하실 수 있습니다.
          </span>
        </Article>
        <Button type="button" onClick={handleClose} autoFocus>
          확인
        </Button>
      </Container>
    </Notify>
  );
};

export default UpdateInactiveUserModal;

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px 24px 24px 24px;
  background-color: white;
  width: 100%;
  max-width: 600px;
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 28px;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: 0.1px;
    color: ${Color.textPrimary};
  }

  .subTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: -0.1px;
    color: ${Color.textSecondary1};
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 17.5px;
  margin-top: 48px;
  color: ${Color.buttonOnPrimary};
  background-color: ${Color.buttonPrimary};
  border: none;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
